import dialogPolyfill from 'dialog-polyfill'
import './components/decimal-seperator'

var Isotope = require('isotope-layout');
var lightSlider = require("./vendor/lightslider");
var Slider = require("./vendor/jquery-slider");
var modal = require("./vendor/modal");

$(document).ready(function () {
    $('.price-guarentee').click(function () {
        window.location = "/vaerd-at-vide/prisgaranti/";
    });

    $('#loanCartForm input').blur(function () {
        if ($(this).hasClass('valid') && (!$('html').hasClass('no-smil'))) {
            $(this).after('<svg class="checkmark-form"><use xlink:href="/Leasy.Assets/dist/svgmin/img.svg#checkmark-two"></use></svg>');
        } else {
            if ($(this).hasClass('valid') && ($('html').hasClass('no-smil'))) {
                console.log('edge')
                $(this).after('<img class="checkmark-IE" src="/Leasy.Assets/dist/img/ok-xxl.png" />');
            }
        }
    })

    var cookieSplitTest = readCookie('eVar23Value');

    if (cookieSplitTest == 'LEASY2017Tese4-Variant1') {
        $('ul.main-nav--inline li:last-of-type a').addClass('t-1').append('<span>Lån op til 50.000,-</span>').parent().prev('li').addClass('no-line');
    }

    if (cookieSplitTest == 'LEASY2017Tese4-Variant2') {
        $('ul.main-nav--inline li:last-of-type a').addClass('t-2');
    }

    if (cookieSplitTest == 'LEASY2018Tese10-Control') {
        $('.mandatory').empty();
    }

    var btnBasket = $('.basket-btn');

    if (cookieSplitTest == 'LEASY2017Tese5-Variant1') {
        var nemIDtext = ('<p style="padding: 28px 0 0;font-weight: bold;">På næste side beder vi dig om at indtaste NemID, det er for din sikkerhed at vi gør dette.<p/>');
        $(nemIDtext).insertBefore(btnBasket);
    }

    if (cookieSplitTest == 'LEASY2017Tese5-Variant2') {
        var nemIDtext = ('<p style="padding: 20px 0 0;font-weight: bold;">På næste side beder vi dig om at indtaste NemID, det er for din sikkerhed at vi gør dette.<p/>');
        $(nemIDtext).insertAfter(btnBasket);
    }

    function goback() {
        window.history.back();
    }

    if ($('html').hasClass('no-flexboxlegacy')) {
        $('.col-half').each(function () {
            var $container = $(this),
                imgUrl = $container.find('img').prop('src');
            if (imgUrl) {
                $container
                    .css('backgroundImage', 'url(' + imgUrl + ')')
                    .addClass('compat-object-fit');
            }
        });
    }

    var previousScroll = 0;
    $('ul.scroll').scroll(function (event) {
        var scroll = $(this).scrollLeft();
        if (scroll > previousScroll) {
            $('.scroll-arrow').fadeOut(500);
        } else {
            $('.scroll-arrow').fadeIn(500);
        }
        previousScroll = scroll;
    });

    //Search
    $('.open-search').click(function () {
        $(this).fadeOut();
        $('.search').addClass('open');
        setTimeout(function () { $('.searchForm .search-input').focus() }, 1000);
    });

    $('.close-search').click(function () {
        $('.open-search').fadeIn();
        $('.search').removeClass('open');
    });

    //Remove counter on receipt
    $('body.kvittering').find('.basket-number').remove();


    //Call Basket API
    if ($('.basket-info').length > 0 && $('.form-payment').length > 0) {
        var paymentTypeId = $('.form-payment input').attr('data-paymenttype');
        var basketId = $('input[name="basketNodeId"]').val();
        var delivery = true;

        $.get("/umbraco/api/CartApi/RecalculateCart?paymentType=" + paymentTypeId + "&withDeliveryFee=" + delivery + "&basketNodeId=" + basketId, function (data) {
            $('.order-box .product--price-box--price').html(data.priceFinancedDisplay);
            $('.order-box .product--price-box--finance').html(data.priceBoxFinancedDisplay);
            $('.order-box .product--price-box--delivery').html(data.priceBoxInstallDisplay);
            $('.order-box .pligt p').html(data.dutyTextDisplay);
            $('.terms .terms-link').attr("href", data.terms.termsUrl);
            $('.terms .terms-link').text(data.terms.termsName);
        });

        $('.form-payment input').click(function () {
            $('.form-payment input').prop('checked', false);
            $(this).prop('checked', true);
            var paymentTypeId = $(this).attr('data-paymenttype');
            if ($('input[name="levering"]').prop('checked')) {
                var delivery = true;
            } else {
                var delivery = false;
            }

            var siteId = $('#siteId').val();

            if (paymentTypeId === '3') {
                if (siteId !== '28') {
                    $('.valgroup.cpr input').attr("data-val", "false");
                    $('.valgroup.cpr').hide();
                    $('.formgroup.account input').attr("data-val", "false");
                    $('.formgroup.account').hide();

                    $('.formgroup.zipCodeCity input').attr("data-val", "true");
                    $('.formgroup.zipCodeCity').show();
                    $('.brZipCodeHide').show();
                }

                $('.checkbox-inline.termsAndCredit input').attr("data-val", "false");
                $('.checkbox-inline.termsAndCredit').hide();
            } else {
                if (siteId !== '28') {
                    $('.valgroup.cpr input').attr("data-val", "true");
                    $('.valgroup.cpr').show();
                    $('.formgroup.account input').attr("data-val", "true");
                    $('.formgroup.account').show();

                    $('.formgroup.zipCodeCity input').attr("data-val", "false");
                    $('.formgroup.zipCodeCity').hide();
                    $('.brZipCodeHide').hide();
                }

                $('.checkbox-inline.termsAndCredit input').attr("data-val", "true");
                $('.checkbox-inline.termsAndCredit').show();
            }

            $('.payment-option').attr('value', 'false');
            $(this).siblings('.payment-option').attr('value', 'true');

            $.get("/umbraco/api/CartApi/RecalculateCart?paymentType=" + paymentTypeId + "&withDeliveryFee=" + delivery + "&basketNodeId=" + basketId, function (data) {
                $('.order-box .product--price-box--price').html(data.priceFinancedDisplay);
                $('.order-box .product--price-box--finance').html(data.priceBoxFinancedDisplay);
                $('.order-box .product--price-box--delivery').html(data.priceBoxInstallDisplay);
                $('.order-box .pligt p').html(data.dutyTextDisplay);
                $('.terms .terms-link').attr("href", data.terms.termsUrl);
                $('.terms .terms-link').text(data.terms.termsName);
            });

            if (siteId === '28' || siteId === '34') {
                if (paymentTypeId !== '1') {
                    $('#yourInfo').show();
                    $('#basketConsents').show();
                    $('.cash-buy').show();
                    $('.accept-loanform').hide();
                }
                else {
                    $('#yourInfo').hide();
                    $('#basketConsents').hide();
                    $('.cash-buy').hide();
                    $('.accept-loanform').show();
                }
            }
        });

        $('.form-delivery input').click(function () {
            var paymentTypeId = $('.form-payment .highlight input').attr('data-paymenttype');

            if ($('input[name="levering"]').prop('checked')) {
                var delivery = true;
            } else {
                var delivery = false;
            }

            $('.delivery-option').attr('value', 'false');
            $(this).siblings('.delivery-option').attr('value', 'true');

            var apiCall = "/umbraco/api/CartApi/RecalculateCart?paymentType=" + paymentTypeId + "&withDeliveryFee=" + delivery + "&basketNodeId=" + basketId;
            console.log(apiCall);
            $.get(apiCall, function (data) {
                $('.order-box .product--price-box--price').html(data.priceFinancedDisplay);
                $('.order-box .product--price-box--finance').html(data.priceBoxFinancedDisplay);
                $('.order-box .product--price-box--delivery').html(data.priceBoxInstallDisplay);
                $('.order-box .pligt p').html(data.dutyTextDisplay);
            });
        });
    }

    $('.basket-btn').on('click', function () {
        var siteId = $('#siteId').val();
        if (siteId == '33') {

            var radios = document.getElementsByName("Finansiering");
            var formValid = false;
            var i = 0;
            while (!formValid && i < radios.length) {
                if (radios[i].checked) formValid = true;
                i++;
            }

            if (!formValid) alert("Du måste välja betalningssätt");
            return formValid;

        }
    });

    //Set cookie
    $('.addToCart').on('click', function (e) {
        //removeCookie('cart');
        var cart = getCookie('cart');

        var productInfo = $('.product-details--info');
        var product = {};
        product.id = $(productInfo).attr('data-product-ax');
        product.name = $(productInfo).attr('data-product-name');
        product.price = $(productInfo).attr('data-product-price');
        if (cart == null) {
            cart = product.id + ',0,standard|';
        } else {
            cart = cart + product.id + ',0,standard|';
        }

        setCookie('cart', cart, 30);

        //Omniture
        s.products = 'new;' + product.id + ';'
        s.events = 'scAdd'; void (s.t());

        console.log(getCookie('cart'));
    });

    //removeCookie('cart');
    // set cookie
    function setCookie(name, value, day) {
        var expireDate = new Date();
        expireDate.setSeconds(expireDate.getSeconds() + day * 24 * 60 * 60 * 1000);
        document.cookie = name + "=" + value + ";path=/;expires=" + expireDate.toGMTString();
    }

    // read from cookie
    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    // remove cookie
    function removeCookie(name) {
        setCookie(name, "", -1);
    }


    //Show/hide specs
    $(".specs-show").click(function () {
        $('html, body').animate({
            scrollTop: $("#tab-description").offset().top - 52
        }, 1000);
        $('#tab-description').fadeOut(500);
        $('a[href*="tab-description"]').removeClass('active');
        $('#tab-specs').fadeIn(500);
        $('a[href*="tab-specs"]').addClass('active');

        return false;
    });



    // Add/remove classes to form radio buttons in cart
    $('.form-payment .form-radio-button input[type=radio]').change(function () {
        $('.form-payment div.highlight').removeClass('highlight');
        $('.payment-option').attr('value', 'false');
        $(this).siblings('.payment-option').attr('value', 'true');
        $(this).parent().addClass('highlight');
    });

    $('.form-delivery .form-radio-button input[type=radio]').change(function () {
        $('.form-delivery div.highlight').removeClass('highlight');
        $(this).parent().addClass('highlight');
    });

    //var siteHref = $(location).attr('href');
    //var sliderDrag = true;

    //if (siteHref.includes(".se")) {
    //    sliderDrag = false;
    //}

    //console.log(sliderDrag)

    // Lightslider settings
    var productSlide = $(".light-slider").lightSlider({
        onSliderLoad: function (el) {
            var maxHeight = 0,
                container = $(el),
                children = container.children();
            children.each(function () {
                var childHeight = $(this).height();
                if (childHeight > maxHeight) {
                    maxHeight = childHeight;
                }
            });
            container.height(maxHeight);
        },
        item: 3,
        slideMove: 1, // slidemove will be 1 if loop is true
        slideMargin: 10,
        useCSS: true,
        easing: 'cubic-bezier(0.25, 0, 0.25, 1', //'for jquery animation',////
        speed: 400, //ms'
        auto: true,
        loop: true,
        slideEndAnimation: true,
        pause: 2000,
        keyPress: false,
        controls: false,
        pager: false,
        enableDrag: false,
        prevHtml: '<img src="./src/img/left.svg" alt="left" />',
        nextHtml: '<img src="./src/img/right.svg" alt="left" />',
        vertical: false,
        adaptiveHeight: false,
        enableDrag: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    item: 2,
                    slideMove: 1,
                    slideMargin: 0
                }
            },
            {
                breakpoint: 480,
                settings: {
                    item: 1,
                    slideMove: 1,
                    slideMargin: 10
                }
            }
        ]
    });

    // Custom img for prev/next controls in lightslider
    $('.slideControls .slidePrev').click(function () {
        productSlide.goToPrevSlide();
    });
    $('.slideControls .slideNext').click(function () {
        productSlide.goToNextSlide();
    });

    if ($(window).width < 768) {
        // Lightslider mobile settings
        var productSlideMobile = $(".product-img-slider").lightSlider({
            item: 1,
            slideMove: 1, // slidemove will be 1 if loop is true
            useCSS: true,
            easing: 'cubic-bezier(0.25, 0, 0.25, 1', //'for jquery animation',////
            speed: 400, //ms'
            auto: false,
            pauseOnHover: false,
            loop: false,
            slideEndAnimation: true,
            pause: 2000,
            keyPress: false,
            controls: false,
            pager: false,
            prevHtml: '<img src="./src/img/left.svg" alt="left" />',
            nextHtml: '<img src="./src/img/right.svg" alt="left" />',
            vertical: false,
            adaptiveHeight: false,
            enableTouch: true,
            enableDrag: true,
            adaptiveHeight: true,
            swipeThreshold: 40,
            slideMargin: 0,
            mode: 'slide'
        });

        // Custom img for prev/next controls in lightslider
        $('.slideControls .slidePrev').click(function () {
            productSlideMobile.goToPrevSlide();
        });
        $('.slideControls .slideNext').click(function () {
            productSlideMobile.goToNextSlide();
        });
    }

    // Lightslider mobile settings
    $(".product-details--img").lightSlider({
        gallery: true,
        item: 1,
        loop: true,
        slideMargin: 0,
        thumbItem: 9,
        thumbMargin: 10,
        enableTouch: true,
        enableDrag: true,
        swipeThreshold: 40
    });

    // init Isotope
    let gridParent = document.querySelector('.product-listing');

    if (gridParent) {
        let grid = gridParent.querySelector('ul.product');

        let iso = new Isotope(grid, {
            itemSelector: '.product--item',
            layoutMode: 'fitRows',
            percentPosition: true,
            fitRows: {
                gutter: 30
            }
        });

        $('.selector ul li.option').click(function () {
            $(this).siblings().addBack().children().remove();
            var a = $(this).siblings().toggle();
            $(this).parent().prepend(this);
            setFilter();
            //$grid.isotope({ filter: filterFns["custom"] });
        })

        var filterProductCount = 0;
        //SetFilter function
        function setFilter() {
            filterProductCount = 0;
            iso.arrange({ filter: filterFns['custom'] });
            $('.product-listing--sort h5').html(filterProductCount + " " + $('#countName').val());
        }

        // filter functions
        var filterFns = {
            // show if number is greater than 50
            yield: function () {
                var price = $(this).attr('data-price');
                return parseInt(price, 10) > parseInt($("#filteramountmin").val()) && parseInt(price, 10) < parseInt($("#filteramountmax").val());
            },
            // show if name ends with -ium
            custom: function () {
                var returnvalue = true;

                //Price
                var price = $(this).attr('data-price');
                if (parseInt(price, 10) >= parseInt($("#filteramountmin").val()) && parseInt(price, 10) <= parseInt($("#filteramountmax").val())) {
                }
                else {
                    returnvalue = false;
                }

                // Brand 
                var brand = $(this).attr('data-brand');
                var brandactive = $(".brand-choice li:first-of-type").attr("data-filter-brand");

                if (brandactive != "") {
                    if (brand != brandactive) {
                        returnvalue = false;
                    }
                }

                // Spec0 
                var spec = $(this).attr('data-type-0');
                var specactive = $(".spec-filter-0 li:first-of-type").attr("data-filter-type-0");

                if (specactive != "") {
                    if (spec != specactive) {
                        returnvalue = false;
                    }
                }

                if (returnvalue) {
                    filterProductCount = filterProductCount + 1;
                }
                // Annoncer skal ikke slåes fra
                if ($(this).find(".productImg").length > 0) {
                    returnvalue = false;
                }
                return returnvalue;
            }
        };


        // jQuery UI-slider used for filtering products
        $('#slider-range').append('<div class="my-handle ui-slider-handle"><img width="27" height="33" style="border:none !important; width:27px; height:33px; width:100%; height:auto;" src="/Leasy.Assets/src/img/svg/slider_laan.svg" /></div>');
        $('#slider-range').prepend('<div class="my-handle ui-slider-handle"><img width="27" height="33" style="border:none !important; width:27px; height:33px; width:100%; height:auto;" src="/Leasy.Assets/src/img/svg/slider_laan.svg" /></div>');
        if ($('#slider-range').length > 0) {
            $(function () {
                $("#slider-range").slider({
                    range: true,
                    min: parseInt($("#filteramountmin").val()),
                    max: parseInt($("#filteramountmax").val()),
                    values: [0, 999],
                    step: 1,
                    slide: function (event, ui) {
                    $("#amount-1").val(ui.values[0] + " " + $("#txtMdrPrice").val());
                    $("#amount-2").val(ui.values[1] + " " + $("#txtMdrPrice").val());
                        $("#filteramountmin").val(ui.values[0]);
                        $("#filteramountmax").val(ui.values[1]);
                    },
                    stop: function (event, ui) {
                        setFilter();
                    }
                })
            $("#amount-1").val($("#slider-range").slider("values", 0) + " " + $("#txtMdrPrice").val());
            $("#amount-2").val($("#slider-range").slider("values", 1) + " " + $("#txtMdrPrice").val());
            });
        }
    }
    // jQuery UI-slider used for product loan slider
    if ($('#loan-slider').length > 0) {
        $('#loan-slider').append('<div class="my-handle ui-slider-handle" style="top: -0.4em;"><img width="27" height="33" src="/Leasy.Assets/src/img/svg/slider_laan.svg" /></div>');

        var loanSliderMin = parseInt($("input.loanValueMin").val());
        var loanSliderMax = parseInt($("input.loanValueMax").val());

        $(function () {

            var slider = $("#loan-slider").slider({
                value: 12,
                min: loanSliderMin,
                max: loanSliderMax,
                step: 1,
                start: function (event, ui) {
                    console.log(ui);
                    $(ui.handle).find('.ui-slider-tooltip').show();
                },
                stop: function (event, ui) {
                    $(ui.handle).find('.ui-slider-tooltip').hide();
                },
                slide: function (event, ui) {
                    $(ui.handle).find('.ui-slider-tooltip').text(ui.value + ' ' + MonthShortName);
                    $('input.loan-value').val(ui.value);
                },
                create: function (event, ui) {
                    var tooltip = $('<div class="ui-slider-tooltip" />').css({
                        display: 'none'
                    });

                    $(event.target).find('.ui-slider-handle').append(tooltip);
                },
                change: function (event, ui) { }
            });
        });
    }

    // jQuery UI-slider used for loan slider
    var loaddata = "";
    // calculator
    var parsedJSON = '';
    if ($('.hero-unit-loan').length > 0 || $('.loan-basket-info').length > 0) {
        $(function () {

            //Appends another handler image
            $('.loan-slider-amount').append('<div class="my-handle ui-slider-handle"><img width="27" height="33" style="max-width:53px; width:27px; height:33px;" src="/Leasy.Assets/src/img/ui-handle-grey.png" /></div>');
            $('.loan-slider-runtime').append('<div class="my-handle ui-slider-handle"><img width="27" height="33" style="max-width:53px; width:27px; height:33px;" src="/Leasy.Assets/src/img/ui-handle-grey.png" /></div>');

            //Amount
            var loanSliderAmount = $('.amount input.loanValue').val();
            var loanSliderAmountSteps = $('.amount input.loanSteps').val();
            var loanSliderMinAmount = $(".amount input.loanValueMin").val();
            var loanSliderMaxAmount = $(".amount input.loanValueMax").val();

            $('.amount-min').html(displayAmount(loanSliderMinAmount.toString()).replace(",-", CurrencyShortName));
            $('.amount-max').html(displayAmount(loanSliderMaxAmount.toString()).replace(",-", CurrencyShortName));

            var cookieAmount = readCookie('amount');
            if (cookieAmount != null) {
                if ($.isNumeric(cookieAmount)) {
                    loanSliderAmount = parseInt(cookieAmount);
                }
                $('.amount input.loanValue').val(cookieAmount);
            } else {
                setCookie('amount', loanSliderAmount, 21);
            }

            var cookieAmountMin = readCookie('amountMin');
            if (cookieAmountMin != null) {
                if ($.isNumeric(cookieAmountMin)) {
                    loanSliderMinAmount = parseInt(cookieAmountMin);
                }
                $('.loan-basket-info .amount input.loanValueMin').val(cookieAmountMin);
                $('.loan-basket-info .amount-min').html(displayAmount(loanSliderMinAmount.toString()));
                $('.const--amount p').html(displayAmount(cookieAmount.toString()));
            } else {
                setCookie('amountMin', loanSliderMinAmount, 21);
            }

            var cookieAmountMax = readCookie('amountMax');
            if (cookieAmountMax != null) {
                if ($.isNumeric(cookieAmountMax)) {
                    loanSliderMaxAmount = parseInt(cookieAmountMax);
                }
                $('.loan-basket-info .amount input.loanValueMax').val(cookieAmountMax);
                $('.loan-basket-info .amount-max').html(displayAmount(loanSliderMaxAmount.toString()));
            } else {
                setCookie('amountMax', loanSliderMaxAmount, 21);
            }

            $('.amount input.loanValue').val(loanSliderAmount).toString();

            //Months
            var loanSliderMonths = $('.months input.loanValue').val();
            var loanSliderSteps = $('.months input.loanValueSteps').val();
            var loanSliderMinMonths = $(".months input.loanValueMin").val();
            var loanSliderMaxMonths = $(".months input.loanValueMax").val();

            $('.months-min').html(loanSliderMinMonths + " " + MonthShortName);
            $('.months-max').html(loanSliderMaxMonths + " " + MonthShortName);

            var cookieMonths = readCookie('months');
            if (cookieMonths != null) {
                if ($.isNumeric(cookieMonths)) {
                    loanSliderMonths = parseInt(cookieMonths);
                }
                $('.months input.loanValue').val(cookieMonths);
                $('.const--month p').html(cookieMonths + " " + MonthShortName);
            } else {
                setCookie('months', loanSliderMonths, 21);
            }

            var cookieMonthsMin = readCookie('monthsMin');
            if (cookieMonthsMin != null) {
                if ($.isNumeric(cookieMonthsMin)) {
                    loanSliderMinMonths = parseInt(cookieMonthsMin);
                }
                $('.loan-basket-info .months input.loanValueMin').val(loanSliderMinMonths);
                $('.loan-basket-info .months-min').html(loanSliderMinMonths + " " + MonthShortName);
            } else {
                setCookie('monthsMin', loanSliderMinMonths, 21);
            }

            var cookieMonthsMax = readCookie('monthsMax');
            if (cookieMonthsMax != null) {
                if ($.isNumeric(cookieMonthsMax)) {
                    loanSliderMaxMonths = parseInt(cookieMonthsMax);
                }
                $('.loan-basket-info .months input.loanValueMax').val(loanSliderMaxMonths);
                $('.loan-basket-info .months-max').html(loanSliderMaxMonths + " " + MonthShortName);
            } else {
                setCookie('monthsMax', loanSliderMaxMonths, 21);
            }

            $('.months input.loanValue').val(loanSliderMonths);

            if (loanSliderMonths >= 61) {
                $(".no-info").css('display', 'block');
            }

            //setCalculatedValues();
            if ($('.loan-slider-amount').length && '.loan-slider-runtime'.length) {

                loaddata = "../../Leasy.Assets/loaddata_dk.json";

                var hostname = window.location.hostname;

                if (hostname.indexOf('leasy.no') > -1 || hostname.indexOf('leasyno2.local') > -1) {
                    loaddata = "../../Leasy.Assets/loaddata_no.json";
                }

                if (hostname.indexOf('leasy.se') > -1 || hostname.indexOf('leasyse2.local') > -1) {
                    loaddata = "../../Leasy.Assets/loaddata_se.json";
                }

                $.getJSON(loaddata, function (data) {

                    parsedJSON = data;

                    for (var i = 0; i < parsedJSON.months.length; i++) {
                        if (parsedJSON.months[i].month == loanSliderMonths && parsedJSON.months[i].amount == loanSliderAmount) {
                            setCalculatedValues(
                                parsedJSON.months[i].amount,
                                parsedJSON.months[i].month,
                                parsedJSON.months[i].monthlyPay,
                                parsedJSON.months[i].debitrente,
                                parsedJSON.months[i].creditTotal,
                                parsedJSON.months[i].aop,
                                parsedJSON.months[i].totalkreditcost,
                                parsedJSON.months[i].kreditcost,
                                parsedJSON.months[i].setupfeeloan
                            );
                        }
                    }
                    var slider = $(".loan-slider-amount").slider({
                        value: parseFloat(loanSliderAmount),
                        min: parseFloat(loanSliderMinAmount),
                        max: parseFloat(loanSliderMaxAmount),
                        step: parseFloat(loanSliderAmountSteps),
                        range: "min",
                        start: function (event, ui) {
                            $(ui.handle).find('.ui-slider-tooltip').show();
                        },
                        //stop: function (event, ui) {
                        //    $(ui.handle).find('.ui-slider-tooltip').hide();
                        //},
                        slide: function (event, ui) {
                            $(ui.handle).find('.ui-slider-tooltip').text(displayAmount(ui.value.toString()));
                            $('.amount input.loanValue').val(ui.value.toString());
                            $(".loan-slider-amount").slider("value", ui.value);
                            //$('.const--amount p').text(displayAmount(ui.value.toString()));
                            for (var i = 0; i < parsedJSON.months.length; i++) {
                                if (parsedJSON.months[i].amount == ui.value.toString() && parsedJSON.months[i].month == $('.months input.loanValue').val()) {
                                    setCalculatedValues(
                                        parsedJSON.months[i].amount,
                                        parsedJSON.months[i].month,
                                        parsedJSON.months[i].monthlyPay,
                                        parsedJSON.months[i].debitrente,
                                        parsedJSON.months[i].creditTotal,
                                        parsedJSON.months[i].aop,
                                        parsedJSON.months[i].totalkreditcost,
                                        parsedJSON.months[i].kreditcost,
                                        parsedJSON.months[i].setupfeeloan
                                    );
                                }
                            }
                            // set cookie if accept
                            setCookie('amount', ui.value.toString(), 21);
                            setCookie('amountMin', loanSliderMinAmount.toString(), 21);
                            setCookie('amountMax', loanSliderMaxAmount.toString(), 21);
                        },
                        create: function (event, ui) {
                            var tooltip = $('<div class="ui-slider-tooltip" />');

                            $(event.target).find('.ui-slider-handle').append(tooltip);
                        },
                        change: function (event, ui) { }
                    });

                    var slider = $(".loan-slider-runtime").slider({
                        value: parseFloat(loanSliderMonths),
                        min: parseFloat(loanSliderMinMonths),
                        max: parseFloat(loanSliderMaxMonths),
                        step: parseFloat(loanSliderSteps),
                        range: "min",
                        start: function (event, ui) {
                            $(ui.handle).find('.ui-slider-tooltip').show();
                            if (ui.value >= 61) {
                                $(".no-info").css('display', 'block');
                            }
                        },
                        stop: function (event, ui) {
                            //$(ui.handle).find('.ui-slider-tooltip').hide();
                            if (ui.value >= 61) {
                                $(".no-info").css('display', 'block');
                            }
                        },
                        slide: function (event, ui) {
                            $(ui.handle).find('.ui-slider-tooltip').text(ui.value + ' ' + MonthShortName);
                            $('.months input.loanValue').val(ui.value.toString());
                            $(".loan-slider-runtime").slider("value", ui.value);
                            for (var i = 0; i < parsedJSON.months.length; i++) {
                                if (parsedJSON.months[i].month == ui.value && parsedJSON.months[i].amount == $('.amount input.loanValue').val()) {
                                    setCalculatedValues(
                                        parsedJSON.months[i].amount,
                                        parsedJSON.months[i].month,
                                        parsedJSON.months[i].monthlyPay,
                                        parsedJSON.months[i].debitrente,
                                        parsedJSON.months[i].creditTotal,
                                        parsedJSON.months[i].aop,
                                        parsedJSON.months[i].totalkreditcost,
                                        parsedJSON.months[i].kreditcost,
                                        parsedJSON.months[i].setupfeeloan
                                    );
                                }
                            }
                            if (ui.value >= 61) {
                                $(".no-info").css('display', 'block');
                            } else {
                                $(".no-info").css('display', 'none');
                            }
                            // set cookie if accept
                            setCookie('months', ui.value.toString(), 21);
                            setCookie('monthsMin', loanSliderMinMonths.toString(), 21);
                            setCookie('monthsMax', loanSliderMaxMonths.toString(), 21);
                        },
                        create: function (event, ui) {
                            var tooltip = $('<div class="ui-slider-tooltip" />');

                            $(event.target).find('.ui-slider-handle').append(tooltip);
                        },
                        change: function (event, ui) { }
                    });
                    $("#loan-slider-amount-1 .ui-slider-tooltip").html(displayAmount(loanSliderAmount.toString()));
                    $("#loan-slider-runtime-1 .ui-slider-tooltip").html(loanSliderMonths + ' ' + MonthShortName);
                    $(".runtime-chosen").html(loanSliderMonths + ' ' + MonthShortName);
                    $(".loan-amount-chosen").html(displayAmount(loanSliderAmount.toString()).replace(",-", CurrencyShortName));
                    $('.ui-slider-handle').on('mouseenter', function () {
                        $(this).find('.ui-slider-tooltip').fadeIn(500);
                    });

                });
            }
        });

        // set calculated text fields
        function setCalculatedValues(amount, month, monthlyPay, debitrente, creditTotal, aop, totalkreditcost, kreditcost, setupfeeloan) {
            $(".calc-amount").html(amount);
            $(".loan-amount-chosen").html(displayAmount(amount).replace(",-", " kr."));
            $(".calc-month").html(month);
            $(".runtime-chosen").html(month + " " + MonthShortName);
            $(".calc-monthlyPay").html(monthlyPay + CurrencyShortName);
            setCookie('calcMonthlyPay', monthlyPay.toString(), 21);
            setPayment(monthlyPay);
            $(".calc-debitrente").html(debitrente);
            $(".calc-creditTotal").html(creditTotal);
            $(".calc-aop").html(aop);
            $(".calc-totalkreditcost").html(totalkreditcost);
            $(".calc-kreditcost").html(kreditcost);
            $(".calc-setupfeeloan").html(setupfeeloan);
        }

        // convert amount from ui-sliders before insert
        function displayAmount(loanSliderAmount) {
            var textvalue = $('.calc-hidden-currency').val();
            textvalue = textvalue.replace("###", loanSliderAmount.slice(loanSliderAmount.length - 3, 6));
            textvalue = textvalue.replace("#", loanSliderAmount.slice(0, loanSliderAmount.length - 3));
            return textvalue;
        }

        function setPayment(payment) {

            payment = payment.replace(" ", "");
            payment = payment.replace(".", "");
            payment = payment.replace(",-", "");
            payment = payment.replace(":-", "");
            payment = payment.replace("kr.", "");
            payment = payment.replace(" ", "");
            $('.calc-hidden-months-payment').val(payment);
        }
    }


    // Mobile menu
    $('.header--top-mobile .menu').click(function (e) {
        e.preventDefault;
        $('.mobile-nav').toggleClass('open');

    });

    $('.mobile-nav .close-mobile-menu').click(function () {
        $('.mobile-nav').removeClass('open');

    });


    // Loan Center settings
    $('.activate-loan').click(function (e) {
        e.preventDefault;
        $('.loan-center, .customer-center-bg').toggleClass('open');
        $('body').toggleClass('modal-open');
    });

    $('.loan-center .close-modal').click(function () {
        $('.loan-center, .customer-center-bg').removeClass('open');
        $('body').removeClass('modal-open');
    });


    // Customer Center settings
    $(".customer-center-btn").click(function () {
        var hostname = window.location.hostname;
        var URL = document.URL;


        window.$zopim || (function (d, s) {
            var z = $zopim = function (c) { z._.push(c) }, $ = z.s =
                d.createElement(s), e = d.getElementsByTagName(s)[0]; z.set = function (o) {
                    z.set.
                        _.push(o)
                }; z._ = []; z.set._ = []; $.async = !0; $.setAttribute('charset', 'utf-8');
            $.src = '//v2.zopim.com/?2bJ9IjikQYk9Bg1ERjMjuuWMsSdRdBfH'; z.t = +new Date; $.
                type = 'text/javascript'; e.parentNode.insertBefore($, e)
        })(document, 'script');

        $(function () {
            window.$zopim(function () {
                $zopim.livechat.hideAll()
                window.$zopim.livechat.setOnChatStart(function (event) {
                    var s = s_gi('leasydk');
                    s.linkTrackVars = "eVar24,events";
                    s.linkTrackEvents = "event13,event14";
                    s.events = "event13,event14";
                    s.eVar24 = window.$zopim.livechat.getName();
                    s.tl(this, 'o', 'Zopim-chat');
                    //s.eVar24 = window.$zopim.livechat.getName();
                });
            });
        });


        $('.chat-input').keyup(function (e) {
            if (e.keyCode == 13) {
                $(".btstartchat")[0].click();
            }
        })
    })
    $('.activate').click(function (e) {
        e.preventDefault;
        $('.customer-center, .customer-center-bg').toggleClass('open');
        $('body').toggleClass('modal-open');
    });

    $('.customer-center .close-modal').click(function () {
        $('.customer-center, .customer-center-bg').removeClass('open');
        $('body').removeClass('modal-open');
    });

    $('.faq-icons a').click(function (e) {
        e.preventDefault;
        var faqLink = $(this).attr('data-faq');
        $('div.' + faqLink).toggleClass('open').siblings().removeClass('open');
    });

    $('.drawer-close').click(function () {
        $(this).parents('.drawer').removeClass('open');
    });

    $('ul.tab').each(function () {
        // For each set of tabs, we want to keep track of
        // which tab is active and its associated content
        var $active, $content, $links = $(this).find('a');

        // If the location.hash matches one of the links, use that as the active tab.
        // If no match is found, use the first link as the initial active tab.
        $active = $($links.filter('[href="' + location.hash + '"]')[0] || $links[0]);
        $active.addClass('active');

        $content = $($active[0].hash);

        // Hide the remaining content
        $links.not($active).each(function () {
            $(this.hash).hide();
        });

        // Bind the click event handler
        $(this).on('click', 'a', function (e) {
            // Make the old tab inactive.
            $active.removeClass('active');
            $content.hide();

            // Update the variables with the new link and content
            $active = $(this);
            $content = $(this.hash);

            // Make the tab active.
            $active.addClass('active');
            $content.show();

            // Prevent the anchor's default click action
            e.preventDefault();
        });
    });

    //Customer center post Contact info
    $(".customer-center-inner .contact--form button").click(function () {
        var callName = $('.call-me-name').val();
        var callPhone = $('.call-me-phone').val();
        var callTime = $('.call-me-time').val();

        $.ajax
            ({
                type: "POST",
                //the url where you want to sent the userName and password to
                url: '/umbraco/api/CustomerServiceApi/Call',
                dataType: 'json',
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify({ "name": callName, "phone": callPhone, "time": callTime }),
                success: function () {
                    $('#call-me-succes').modal();
                },
                error: function () {
                    $('#call-me-fail').modal();
                }
            })
    });

    // Compare products
    if ($(window).width() > 1160) {

        $(".customer-center-inner .contact--form button").click(function () {
            var callName = $('.call-me-name').val();
            var callPhone = $('.call-me-phone').val();
            var callTime = $('.call-me-time').val();

            $.ajax
                ({
                    type: "POST",
                    //the url where you want to sent the userName and password to
                    url: '/umbraco/api/CustomerServiceApi/Call',
                    dataType: 'json',
                    contentType: "application/json; charset=utf-8",
                    data: JSON.stringify({ "name": callName, "phone": callPhone, "time": callTime }),
                    success: function () {
                        $('#call-me-succes').modal();
                    },
                    error: function () {
                        $('#call-me-fail').modal();
                    }
                })
        });


        $('.compare-container').hide();

        $('.compare-products ').on($.modal.CLOSE, function (event, modal) {
            $('.cd-products-columns, .cd-features-list').empty();
        });

        $('.compare input').click(function () {
            calculate();
            $('.compare-container').fadeIn(300);
            $('.compare').on('click', 'input:checkbox', calculate);
        })

        function getCheckedProducts() {
            return $.map($('input:checkbox:checked'), function (e, i) {
                return { "name": $(e).attr("data-productname"), "axId": e.value };
            });
        }

        function calculate() {
            $('.compare-container ul').html('<li>' + getCheckedProducts().map(function (e) { return e.name; }).join('</li><li>') + '</li>');
        }

        $("[data-trigger=compare]").click(function () {
            $(this).parents('.compare-container').hide();
            var checkedProducts = getCheckedProducts();
            $('ul.product input:checkbox:checked').prop('checked', false);

            $.ajax({

                type: "POST",
                //the url where you want to sent the userName and password to
                url: '/umbraco/api/ProductApi/CompareProducts',
                dataType: 'json',
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify({ "Ids": checkedProducts.map(function (e) { return e.axId }), "CategoryId": $("input.catID").val() }),
                success: function (data) {
                    //console.log(data);


                    var specsList = "";
                    $.each($(data.specs), function () {
                        specsList += '<li>' + this.specType + '</li>';
                    })

                    var productSpecsList = "";
                    $.each(data.products, function (index, product) {
                        var productDom = $("<li/>");
                        productDom.addClass("product");
                        var topInfoDom = $("<div/>");
                        topInfoDom.addClass("top-info");
                        topInfoDom.append("<img src=\"" + product.image + "\" alt=\"" + product.name + "\"><h3>" + product.name + "</h3>").appendTo(productDom);

                        var featuresList = $("<ul/>");
                        featuresList.addClass("cd-features-list");


                        $.each(product.productSpecs, function (index, spec) {
                            featuresList.append("<li>" + spec + "</li>");
                        });

                        featuresList.appendTo(productDom);

                        productDom.appendTo(".cd-products-columns");
                    })

                    $('.features .cd-features-list').append(specsList);
                    $('#compare-products').modal();
                },
                error: function (data) {
                    console.log("error", data);
                }
            })
        })

    }


    
    // Equal height on receipt page
    var boxHeight = $('.receipt-order').height();
    $('.order-box-price-container').css('height', boxHeight);

    // FAQ Accordion
    $('.loan-faq--list li p.large').click(function (event) {
        /* Act on the event */
        $('.faq-answer').addClass('hide');
        $(this).next('.faq-answer').removeClass('hide');
    });

    // Img zoom & thumbs settings
    //if ($('#img_01').length > 0) {

    //	$('#img_01').ezPlus({
    //		gallery: 'gal1',
    //		cursor: 'cross',
    //		galleryActiveClass: 'active',
    //		imageCrossfade: true,
    //		loadingIcon: false,
    //		zoomType: 'lens',
    //		lensShape: 'round',
    //		lensSize: 200
    //	});

    //	//pass the images to Fancybox
    //	$('#img_01').bind('click', function (e) {
    //		var ez = $('#img_01').data('ezPlus');
    //		$.fancyboxPlus(ez.getGalleryList());
    //		return false;
    //	});
    //};


    // Loan form hide/show fields 
    $('#LoanBasketFormModel_Residence').change(function () {
        if ($(this).val() == 'Andet') {
            $('.hidden-1').show();
        } else {
            $('.hidden-1').hide();
        }
    });

    $('#LoanBasketFormModel_ResidencePermitType').change(function () {
        if ($(this).val() == 'Midlertidig') {
            $('.hidden-4').show();
        } else {
            $('.hidden-4').hide();
            $('.hidden-4').find('input').attr("data-val", "true").attr("data-val", "false");
        }
    });

    $('#LoanBasketFormModel_ResidencePermitType').change(function () {
        if ($(this).val() == 'Permanent') {
            $('.hidden-3').show();
        } else {
            $('.hidden-3').hide();
        }
    });

    $(function () {
        $('i.icon-info').on("click", function () {
            $(this).parent().find('.tooltip').addClass('tooltip-active');        
        });

        $(".tooltip .close-tooltip").on("click", function () {
            $(".tooltip").removeClass("tooltip-active");
        });

    });


    // Black Friday
    var bfFooter = $('.bf-sticky-footer');

    $(window).on('scroll', function () {
        var scrolled = $(this).scrollTop();
        if (scrolled > 200) {
            $(bfFooter).addClass('open');
        } else {
            $(bfFooter).removeClass('open');
        }
    });

    // Leasy No Receipt form

    $('.eier').hide();
    $('.leier').hide();

    $('#LoanBasketFormModelNO_HouseType').change(function () {
        var value = $(this).find('option:selected').val();
        if (value == 'Eier bolig') {
            $('.eier').show();
            $('.leier').show();
        } else if (value == 'Leier bolig') {
            $('.leier').show();
            $('.eier').hide();
        }
    });

    $('.cardata').hide();

    $('#LoanBasketFormModelNO_CarOptions').change(function () {
        var value = $(this).find('option:selected').val();
        if (value == 'Ja') {
            $('.cardata').show();
        } else if (value == 'Nei') {
            $('.cardata').hide();
        }
    });

    $('.studentdata').hide();

    $('#LoanBasketFormModelNO_StudentOptions').change(function () {
        var value = $(this).find('option:selected').val();
        if (value == 'Ja') {
            $('.studentdata').show();
        } else if (value == 'Nei') {
            $('.studentdata').hide();
        }
    });

    $('.studentdata').hide();

    $('.loandata').hide();

    $('#LoanBasketFormModelNO_LoanOptions').change(function () {
        var value = $(this).find('option:selected').val();
        if (value == 'Ja') {
            $('.loandata').show();
        } else if (value == 'Nei') {
            $('.loandata').hide();
        }
    });
});

// set cookie
function setCookie(name, value, day) {
    var expireDate = new Date();
    expireDate.setSeconds(expireDate.getSeconds() + day * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + value + ";path=/;expires=" + expireDate.toGMTString();
}

// read from cookie
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

// remove cookie
function removeCookie(name) {
    setCookie(name, "", -1);
}

const gaUserTracking = {
    init: function () {
        console.log('test')
    }
}



// Init Dialog poly
const loaderDialog = document.querySelector('.basket-loader');

if (loaderDialog) {
    dialogPolyfill.registerDialog(loaderDialog);
}







